import "./style.css";
import React from "react";
const YanaIcon = "/yana-logo.svg";
const StorageIcon = "/storage-logo.svg";
const CloudIcon = "/cloud-logo.svg";

export const ProductsMenu = ({}) => {
  return <div className={"products-menu-block"}>
    <div className={"products-menu-item"}>
      <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 18L20 18" stroke="#ffffff" strokeWidth="2" strokeLinecap="round"/>
        <path d="M4 12L20 12" stroke="#ffffff" strokeWidth="2" strokeLinecap="round"/>
        <path d="M4 6L20 6" stroke="#ffffff" strokeWidth="2" strokeLinecap="round"/>
      </svg>
    </div>
    <div className={"products-menu-content"}>
      <a href={"https://app.chainback.org"} className={"products-menu-link-container"}>
        <span className={"products-menu-link-icon"}><img src={StorageIcon}/></span>
        <span className={"products-menu-link"}>Chainback Storage</span>
      </a>
      <a href={"https://cloud.chainback.org"} className={"products-menu-link-container"}>
        <span className={"products-menu-link-icon"}><img src={CloudIcon}/></span>
        <span className={"products-menu-link"}>Chainback Cloud</span>
      </a>
      <a href={"https://yanabot.io"} className={"products-menu-link-container"}>
        <span className={"products-menu-link-icon"}><img src={YanaIcon}/></span>
        <span className={"products-menu-link"}>Yana AI</span>
      </a>
    </div>
  </div>;
}
